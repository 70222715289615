<template>

<app-wrapper>

	<app-banner />

	<app-wrapper :content="true" class="generic-content" :class="{'has-side': $state.page.panel_enabled || $state.page.siblings}">

		<app-text :text="$state.page.content" />

		<div v-if="$state.page.panel_enabled || $state.page.siblings">

			<app-panel v-if="$state.page.panel_enabled" />

			<app-panel theme="white" title="Other pages" class="siblings" :noimage="true" v-if="$state.page.siblings.length">

				<router-link :to="{ path: sibling.url }" class="siblings-item" v-for="(sibling, index) in $state.page.siblings" :key="index">{{ sibling.name }}<i class="fa-solid fa-chevron-right"></i></router-link>

			</app-panel>

		</div>

	</app-wrapper>

	<app-wrapper class="request-form" v-if="$state.page.request_enabled">

		<app-text text="<h2>Request a consultation</h2>" align="center" />

		<com-form :is-simple="true" />

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

	components: {

		'com-form': () => import('./request/Form')

	}
	
}

</script>

<style scoped>

.generic-content {
	padding: 64px 20px!important;
	display: grid;
	align-items: start;
	grid-template-columns: 1fr;
	grid-gap: 0px;
}

.generic-content.has-side {
	grid-template-columns: auto 408px;
	grid-gap: 68px;
}

.is-tablet .generic-content {
	padding: 48px 20px!important;
	grid-template-columns: 1fr;
	grid-gap: 0px;
}

.is-mobile .generic-content {
	padding: 32px 20px!important;
	grid-template-columns: 1fr;
	grid-gap: 0px;
}

.is-tablet .siblings {
	padding: 20px!important;
	margin-top: 0px!important;
}

.is-mobile .siblings {
	padding: 20px!important;
	margin-top: 0px!important;
}

.siblings-item {
	display: block;
	font-size: 16px;
	line-height: 28px;
	font-weight: 700;
	padding: 14px 68px 14px 20px;
	color: #45464B;
	border-bottom: 1px solid #F5F5F5;
}

.is-desktop .siblings-item:hover {
	background-color: #485CC7;
	color: #fff;
}

.is-mobile .siblings-item {
	font-size: 14px;
	line-height: 20px;
	padding: 14px 48px 14px 0px;
}

.siblings-item i {
	position: absolute;
	top: 21px;
	right: 20px;
	color: #FF2D07;
	font-size: 14px;
}

.is-mobile .siblings-item i {
	right: 0px;
	top: 19px;
}

.request-form {
	max-width: 780px;
    margin: 0px auto;
}

</style>
