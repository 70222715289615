<template>

<app-wrapper ref="wrapper" :data-align="align" class="text" v-html="text" />

</template>

<script>

export default {

	props: ['align', 'text'],

	created: function() {
	
		this.$nextTick(function() {
		
			this.analyseContent()
		
		}.bind(this))
	
	},

	methods: {
	
		analyseContent: function() {

			var tds = this.$refs.wrapper.$el.querySelectorAll('td')

			if(tds.length) {
			
				if(tds[0].textContent.toLowerCase() === 'faq') {
				
					var parent = tds[0].closest('.table-outer')
					parent.classList.add('faq')

					var rows = parent.querySelectorAll('tr')
					rows[rows.length - 2].classList.add('last')

					var viewing = false

					this.$_.each(rows, function(row, index) {
					
						if (index % 2 === 1) {
						
							row.addEventListener('click', function() {

								if(row.classList.contains('view')) {
								
									rows[viewing].classList.remove('view')
									rows[viewing + 1].classList.remove('view')
									
									viewing = false

								} else {

									if(viewing) {

										rows[viewing].classList.remove('view')
										rows[viewing + 1].classList.remove('view')
									
									}

									viewing = index
								
									rows[index].classList.add('view')
									rows[index + 1].classList.add('view')
								
								}
							
							})
						
						}
					
					})
				
				}
			
			}

		}
	
	}

}

</script>

<style scoped>

.text >>> h2 {
	font-weight: 500;
    font-size: 24px;
    color: #1A2046;
    margin-bottom: 28px;
	padding-bottom: 22px;
	position: relative;
	text-transform: uppercase;
	letter-spacing: 8.4px;
	line-height: 40px;
}

.is-device .text >>> h2 {
	font-size: 20px;
	letter-spacing: 7px;
}

.text >>> h2:after {
	content: '';
	display: block;
	position: absolute;
	left: 0px;
	bottom: -2px;
	background-color: #FF2D07;
	height: 4px;
	width: 120px;
}

.text[data-align="center"] {
	text-align: center;
}

.text[data-align="center"] >>> h2:after {
	left: 50%;
	margin-left: -60px;
}

.text >>> h3 {
	font-weight: 700;
    font-size: 22px;
    color: #1A2046;
    margin-bottom: 28px;
}

.is-inverse .text >>> h3 {
	color: #fff;
}

.is-device .text >>> h3 {
	font-size: 18px;
	line-height: 30px;
}

.text >>> p {
	font-weight: 500;
    font-size: 16px;
	line-height: 28px;
    color: #45464B;
    margin-bottom: 28px;
}

.is-device .text >>> p {
	font-size: 14px;
}

.panel .text >>> p,
.is-inverse .text >>> p {
	color: #fff;
}

.text >>> b,
.text >>> strong {
	font-weight: bold;
}

.text >>> i,
.text >>> em {
	font-style: italic;
}

.text >>> ol {
	list-style: decimal;
	margin-left: 20px;
}

.text >>> img[align="left"] {
	margin: 0px 20px 20px 0px;
}

.text >>> img[align="right"] {
	margin: 0px 0px 20px 20px;
}

.text >>> ul,
.text >>> ol {
    margin-bottom: 28px;
}

.text >>> li {
	font-weight: 500;
    font-size: 16px;
	line-height: 28px;
    color: #45464B;
    margin-bottom: 10px;
	padding-left: 28px;
}

.text >>> ol li {
	padding-left: 8px;
}

.is-device .text >>> li {
	font-size: 14px;
	margin-bottom: 5px;
}

.panel .text >>> li,
.is-inverse .text >>> li {
	color: #fff;
}

.text >>> ul li:before {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #FF2D07;
	content: '';
	display: block;
	position: absolute;
	left: 0px;
	top: 10px;
}

.panel .text >>> li:before,
.is-inverse .text >>> li:before{
	background-color: #fff;
}

.text >>> blockquote {
	margin-bottom: 30px;
	border-left: 4px solid #FF2D07;
	padding-left: 20px;
}

.is-device .text >>> blockquote {
	margin-bottom: 40px;
}

.text >>> blockquote p {
	font-family: 'Poppins', sans-serif;
	color: #485CC7;
	font-size: 16px;
	margin-bottom: 0px;
	line-height: 28px;
}

.is-mobile .text >>> blockquote p {
	font-size: 16px!important;
	line-height: 30px;
}

.text >>> blockquote p strong,
.text >>> blockquote p b {
	font-size: 16px;
	margin-top: 20px;
	font-family: 'Montserrat', sans-serif;
	line-height: 30px;
	position: relative;
	font-weight: 500;
	display: block;
	color: #1A2046;
}

.is-mobile .text >>> blockquote p strong,
.is-mobile .text >>> blockquote p b {
	font-size: 14px;
	line-height: 20px;
}

.text >>> *:last-child {
	margin-bottom: 0px;
}

.table-outer {
	margin-bottom: 28px;
}

.is-mobile .text >>> .table-outer {
	width: calc(100vw - 40px);
}

.is-mobile .text >>> .table-outer:not(.faq) .table-inner {
	max-width: calc(100% + 20px);
	min-width: calc(100% + 20px);
	overflow: scroll;
}

.is-mobile .text >>> .table-inner .table {
	margin-right: 20px;
}

.text >>> table {
	max-width: 100%!important;
	width: auto!important;
	margin-bottom: 28px;
	border-spacing: 4px;
	border-collapse: separate;
	display: block;
    overflow-x: auto;
}

.text >>> table tbody {
    display: table;
    width: 100%;
}

.is-device .text >>> table {
	border-spacing: 2px;
}

.text >>> table td {
	background-color: #ECEEF9;
	padding: 20px;
	font-size: 16px;
	color: #45464B;
	line-height: 28px;
}

.is-device .text >>> table td {
	font-size: 14px;
	padding: 10px;
}

.text >>> table tr:first-child td:first-child {
	border-top-left-radius: 10px;
}

.text >>> table tr:first-child td:last-child {
	border-top-right-radius: 10px;
}

.text >>> table tr:last-child td:first-child {
	border-bottom-left-radius: 10px;
}

.text >>> table tr:last-child td:last-child {
	border-bottom-right-radius: 10px;
}

.text >>> a {
	color: #485CC7;
}

.is-inverse .text >>> a {
	color: #FEC32D;
}

.text >>> a:hover {
	text-decoration: underline;
}

.text >>> .table-outer {
	margin-bottom: 28px;
}

.text >>> .table-outer.faq table tr:nth-child(even) td {
	border-radius: 10px;
	background-color: #485CC7;
	color: #fff;
	font-size: 20px;
	font-weight: 500;
	letter-spacing: 2px;
	font-family: 'Montserrat', sans-serif;
}

.text >>> .table-outer.faq table tr:nth-child(even) td p {
	color: #fff;
	font-size: 20px;
	font-weight: 500;
	letter-spacing: 2px;
	font-family: 'Montserrat', sans-serif;
}

.is-mobile .text >>> .table-outer.faq table tr:nth-child(even) td,
.is-mobile .text >>> .table-outer.faq table tr:nth-child(even) td p {
	font-size: 14px;
}

.text >>> .table-outer.faq table tr:nth-child(even):hover td {
	background-color: #3a4db5;
	cursor: pointer;
}

.text >>> .table-outer.faq table tr:nth-child(even).view td {
	background-color: #FEC32D;
	color: #343434;
}

.text >>> .table-outer.faq table tr:nth-child(even).view td p {
	color: #343434;
}

.text >>> .table-outer.faq table tr:nth-child(odd) td {
	background-color: #fff;
}

.text >>> .table-outer.faq table tr:nth-child(odd):not(.view) {
	display: none;
}

</style>
